import { default as _91articleSlug_9389TQqyCTimMeta } from "/code/pages/[sectionSlug]/[articleSlug].vue?macro=true";
import { default as indexTIlYC3Lrk1Meta } from "/code/pages/[sectionSlug]/index.vue?macro=true";
import { default as _91gallerySlug_935zxqqRZZCaMeta } from "/code/pages/[sectionSlug]/photos/[gallerySlug].vue?macro=true";
import { default as indexKRDw4UYEiPMeta } from "/code/pages/index.vue?macro=true";
import { default as newslettersz4uzgmkKRTMeta } from "/code/pages/newsletters.vue?macro=true";
import { default as preview8aS67GwPGXMeta } from "/code/pages/preview.vue?macro=true";
import { default as searchyhVHf7pDp0Meta } from "/code/pages/search.vue?macro=true";
import { default as sponsoredN4DToZYWOzMeta } from "/code/pages/sponsored.vue?macro=true";
import { default as _91staffSlug_93aG9mdKptxXMeta } from "/code/pages/staff/[staffSlug].vue?macro=true";
import { default as _91tagSlug_93fUwrwbhoN5Meta } from "/code/pages/tags/[tagSlug].vue?macro=true";
export default [
  {
    name: "sectionSlug-articleSlug",
    path: "/:sectionSlug()/:articleSlug()",
    component: () => import("/code/pages/[sectionSlug]/[articleSlug].vue").then(m => m.default || m)
  },
  {
    name: "sectionSlug",
    path: "/:sectionSlug()",
    component: () => import("/code/pages/[sectionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "sectionSlug-photos-gallerySlug",
    path: "/:sectionSlug()/photos/:gallerySlug()",
    component: () => import("/code/pages/[sectionSlug]/photos/[gallerySlug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/code/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletters",
    path: "/newsletters",
    component: () => import("/code/pages/newsletters.vue").then(m => m.default || m)
  },
  {
    name: "preview",
    path: "/preview",
    component: () => import("/code/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/code/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "sponsored",
    path: "/sponsored",
    component: () => import("/code/pages/sponsored.vue").then(m => m.default || m)
  },
  {
    name: "staff-staffSlug",
    path: "/staff/:staffSlug()",
    component: () => import("/code/pages/staff/[staffSlug].vue").then(m => m.default || m)
  },
  {
    name: "tags-tagSlug",
    path: "/tags/:tagSlug()",
    component: () => import("/code/pages/tags/[tagSlug].vue").then(m => m.default || m)
  }
]